<template>
	<div
	v-if="view == 'nuevo-pedido'">
		<select-product></select-product>
		<header-component></header-component>
		<description-table-number></description-table-number>
		<list></list>
	</div>
</template>
<script>
import SelectProduct from '@/components/orders/components/create/SelectProduct'
import HeaderComponent from '@/components/orders/components/create/header/Index'
import List from '@/components/orders/components/create/List'
import DescriptionTableNumber from '@/components/orders/components/create/DescriptionTableNumber'
export default {
	components: {
		SelectProduct,
		HeaderComponent,
		List,
		DescriptionTableNumber,
	},
	created() {
		console.log('creadoooo')
		this.$store.commit('order/setModel', {model: null})
		this.$store.commit('order/setProducts', [])
		this.$store.commit('order/setTotal')
		this.$store.commit('order/setIndex', 0)
	}
}
</script>