<template>
	<b-form-row
	class="row-create-order-header"
	v-if="products.length">
		<b-col
		cols="12"
		md="6">
			<b-input-group
			prepend="Descipcion">
				<b-form-textarea
				placeholder="Ingrese una aclaracion para el pedido"
				v-model="model.description"></b-form-textarea>
			</b-input-group>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<div 
			class="select-salon-mesa">
				<b-form-select
				:options="getOptions({key: 'salon_id', text: 'Salon', select_prop_name: 'nombre'})"
				v-model="model.salon_id"></b-form-select>
				<b-button
				v-b-modal="'select-mesa'"
				variant="primary">
					{{ mesa_btn_text }}				
				</b-button>
				<select-mesa></select-mesa>
			</div>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-input-group
			prepend="Mozo">
				<b-form-input
				placeholder="Ingrese el mozo"
				v-model="model.waiter"></b-form-input>
			</b-input-group>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-input-group
			prepend="Descuento">
				<b-form-input
				@keyup="setTotal"
				type="number"
				placeholder="Ingrese el descuento para toda la venta"
				v-model="model.discount"></b-form-input>
			</b-input-group>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-form-select
			:options="getOptions({key: 'order_status_id', text: 'Estado del pedido'})"
			v-model="model.order_status_id"></b-form-select>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-form-select
			:options="getOptions({key: 'payment_method_id', text: 'Metodo de pago'})"
			v-model="model.payment_method_id"></b-form-select>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-form-select
			:options="getOptions({key: 'order_type_id', text: 'Tipo de pedido'})"
			v-model="model.order_type_id"></b-form-select>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-checkbox
			:value="1"
			:unchecked-value="0"
			v-model="model.is_paid">
				Mesa libre
			</b-checkbox>
		</b-col>
		<b-col
		cols="12"
		md="6">
			<b-checkbox
			:value="1"
			:unchecked-value="0"
			v-model="model.sonido">
				Sonido
			</b-checkbox>
		</b-col>
	</b-form-row>
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	methods: {
		setTotal() {
			this.$store.commit('order/setTotal')
		}
	},
	computed: {
		mesa_btn_text() {
			if (this.model.mesa_id) {
				return 'Mesa '+this.model.mesa.nombre 
			}
			return 'Seleccionar Mesa'
		},
	},
	components: {
		SelectMesa: () => import('@/components/orders/modals/SelectMesa'),
	},
}
</script>
<style lang="sass">
.row-create-order-header
	[class^='col']
		margin-bottom: 1em

	.select-salon-mesa
		display: flex 
		flex-direction: row
		justify-content: space-between
		align-items: center 
		select 
			width: 48%
		button 
			width: 48%
</style>