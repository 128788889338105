<template>
	<div>
		<b-table
		class="s-2 b-r-1"
		v-if="items.length"
		head-variant="dark"
		:fields="fields"
		:items="items">
			<template #cell(discount)="data">
				<b-form-input
				type="number"
				@keyup="setTotal"
				placeholder="Descuento"
				v-model="products[data.index].pivot.discount"></b-form-input>
			</template>
			<template #cell(options)="data">
				<b-button
				@click="increment(products[data.index])"
				size="sm"
				variant="primary">
					<i class="icon-plus"></i>
				</b-button>
				<b-button
				@click="decrement(products[data.index])"
				class="m-l-10"
				size="sm"
				variant="primary">
					<i class="icon-minus"></i>
				</b-button>
			</template>
		</b-table>
		<p 
		v-else
		class="text-with-icon">
			<i class="icon-eye-slash"></i>
			No hay productos ingresados
		</p>
	</div>
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	computed: {
		fields() {
			return [
				{ key: 'name', label: 'Nombre', class: 'text-center', sortable: true },
				{ key: 'price', label: 'Precio', class: 'text-center', sortable: true },
				{ key: 'amount', label: 'Cantidad', class: 'text-center', sortable: true },
				{ key: 'discount', label: 'Descuento', class: 'text-center', sortable: true },
				{ key: 'options', label: '', class: 'text-center' },
			]
		},
		items() {
			let items = []
			this.products.forEach(item => {
				items.push({
					name: item.name,
					amount: item.pivot.amount,
					price: this.price(item.price),
					discount: item.pivot.discount,
				})
			})
			return items
		},
	},
	methods: {
		increment(model) {
			model.pivot.amount++
			this.$store.commit('order/updateProduct', model)
			this.$store.commit('order/setTotal')
		},
		decrement(model) {
			// if (this.is_owner || !this.model.id) {
				if (model.pivot.amount > 1) {
					model.pivot.amount--
					this.$store.commit('order/updateProduct', model)
				} else {
					this.$store.commit('order/removeProduct', model)
				}
				this.$store.commit('order/setTotal')
			// }
		},
		setTotal() {
			this.$store.commit('order/setTotal')
		}
	}
}
</script>