<template>
	<search-component
	class="m-b-15"
	model_name="product"
	clear_query
	@setSelected="setSelected"></search-component>
</template>
<script>
export default {
	components: {
		SearchComponent: () => import('@/common-vue/components/search/Index')
	},
	methods: {
		setSelected(result) {
			let item_to_add = {
				...result.model,
				pivot: {
					price: result.model.price,
					amount: 1,
					discount: '',
				}
			}
			this.$store.commit('order/addProduct', item_to_add)
			this.$store.commit('order/setTotal')
		}
	}
}
</script>