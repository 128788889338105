<template>
	<btn-loader
	@clicked="saveOrder"
	:block="false"
	:text="text"
	:loader="saving"></btn-loader>
</template>
<script>
import BtnLoader from '@/common-vue/components/BtnLoader'

import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		BtnLoader,
	},
	data() {
		return {
			saving: false,
		}
	},
	computed: {
		text() {
			if (this.model.id) {
				return 'Actualizar'
			} 
			return 'Hacer Pedido'
		},
	},
	methods: {
		saveOrder() {
			if (this.check() && !this.saving) {
				this.saving = true
				if (this.model.id) {
					this.$api.put('order/'+this.model.id, {
						...this.model,
						products: this.products,
					})
					.then(res => {
						this.update(res)
					})
					.catch(err => {
						this.catch(err)
					})
				} else {
					this.$api.post('order', {
						...this.model,
						products: this.products,
					})
					.then(res => {
						this.update(res)
					})
					.catch(err => {
						this.catch(err)
					})
				}
			}
		},
		update(res) {
			this.saving = false 
			this.$toast.success('Pedido guardado')
			this.$store.commit('order/add', res.data.model)
			if (this.model.id) {
				this.$store.commit('order/setIndex', 0)
			}
			this.resetCreate()
            console.log('order models')
            console.log(this.$store.state.order.models)
		},
		catch(err) {
			this.saving = false 
			this.$toast.error('Error al guardar pedido')
			console.log(err)
		},
		check() {
			if (!this.products.length) {
				return false
			}
			return true
		},
	}
}
</script>