<template>
	<div class="j-between align-center m-b-15">
		<total></total>	
		<div>
			<previus-orders></previus-orders>
			<btn-save></btn-save>
		</div>
	</div>
</template>
<script>
import Total from '@/components/orders/components/create/header/Total'
import PreviusOrders from '@/components/orders/components/create/header/PreviusOrders'
import BtnSave from '@/components/orders/components/create/header/BtnSave'

import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		Total,
		PreviusOrders,
		BtnSave,
	},
}
</script>