<template>
	<b-button-group
	class="m-r-15">
		<template
		v-if="model.id">
			<b-button
			@click="clear"
			variant="outline-primary">
				Cancelar
			</b-button>
			<b-button
			variant="dark">
				N° {{ model.num }}
			</b-button>
			<b-button
			@click="print"
			variant="outline-danger">
				<i class="icon-print"></i>
			</b-button>
		</template>
		<btn-loader
		:block="false"
		:loader="loading_previus"
		@clicked="increment"
		icon="left">
		</btn-loader>
		<btn-loader
		:disabled="disabled_next"
		:block="false"
		:loader="loading_next"
		@clicked="decrement"
		icon="right">
		</btn-loader>
	</b-button-group>
</template>
<script>
import BtnLoader from '@/common-vue/components/BtnLoader'

import orders from '@/mixins/orders'
export default {
	mixins: [orders],
	components: {
		BtnLoader,
	},
	data() {
		return {
			loading_previus: false,
			loading_next: false,
		}
	},
	computed: {
		index() {
			return this.$store.state.order.index
		},
		disabled_next() {
			return this.index == 0
		},
	},
	methods: {
		print() {
			let link = process.env.VUE_APP_API_URL+'/order/pdf/'+this.model.id 
			window.open(link)
		},
		increment() {
			this.loading_previus = true
			this.$store.commit('order/incrementIndex')
			this.getOrder()
		},
		decrement() {
			this.$store.commit('order/decrementIndex')
			if (this.index == 0) {
				this.clear()
			} else {
				this.loading_next = true
				this.getOrder()
			}
		},
		getOrder() {
			this.$api.get('previus-next/order/'+this.index)
			.then(res => {
				this.loading_previus = false 
				this.loading_next = false 
				let order = res.data.model
				console.log(order)
				if (order) {
					this.$store.commit('order/setModel', {model: order})
					this.$store.commit('order/setProducts', order.products)
					// this.setProductsPivotProps(order.products)
					this.$store.commit('order/setTotal')
				}
			})
			.catch(err => {
				console.log(err)
				this.loading_previus = false
				this.loading_next = false 
				this.$store.$toast.error('Error al buscar pedido')
			})
		},
		setProductsPivotProps(products) {
			// products.forEach(product => {
			// 	product.price = product.pivot.price
			// 	product.amount = product.pivot.amount
			// 	product.discount = product.pivot.discount
			// })
			this.$store.commit('order/setProducts', products)

		},
		clear() {
			this.$store.commit('order/setIndex', 0)
			this.resetCreate()
		}
	}
}
</script>