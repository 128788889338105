<template>
	<p 
	class="total">
		Total: {{ price(total) }}
	</p>
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders],
}
</script>
<style lang="sass">
.total 
	margin-bottom: 0
	font-weight: bold
	font-size: 1.5em
</style>